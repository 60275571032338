export default {
    namespaced: true,

    state: {
        prizes: [
            {
                name: 'bol',
                displayName: 'Bol.com bon',
                visual: '/static/img/bol-kaart.png',
                twv: '20',
                hovering: false
            },
            {
                name: 'lot',
                displayName: '10 Lotto loten',
                visual: '/static/img/lot.png',
                twv: '20',
                populair: true,
                hovering: false
            },
            {
                name: 'mediamarkt',
                displayName: 'MediaMarkt bon',
                visual: '/static/img/mm-kaart.png',
                twv: '20',
                hovering: false
            }
        ],

        selectedPrize: 'lot'
    },

    getters: {
        data: state => state.prizes,
        selectedPrize: state => state.selectedPrize,

        selectedPrizeData: state => state.prizes.find( prize => prize.name === state.selectedPrize ),

        currentHover: state => state.prizes.find( prize => prize.hovering === true )
    },

    mutations: {
        setSelectedPrize: ( state, payload ) =>
        {
            state.selectedPrize = payload;
        },

        changeHoverState: ( state, payload ) =>
        {
            let prize = state.prizes.find( prize => prize.name === payload.name );
            prize.hovering = payload.value;
        }
    },

    actions: {
        select: ( context, payload ) =>
        {
            context.commit( 'setSelectedPrize', payload );
        },

        setHover: ( context, payload ) =>
        {
            context.commit( 'changeHoverState', { name: payload, value: true });
        },

        unsetHover: ( context, payload ) =>
        {
            context.commit( 'changeHoverState', { name: payload, value: false });
        }
    }
};
