<template>
    <div class="usp-list">
        <div class="usp">
            <div class="icon-wrapper">
                <img draggable="false" src="/static/img/usp-kado.svg" />
            </div>

            <div class="label">
                <p><span class="bold">Kies een cadeau uit</span> <br class="hide-for-small"/>wanneer je nu Lotto abonnee wordt</p>
            </div>
        </div>

        <div class="usp">
            <div class="icon-wrapper">
                <img draggable="false" src="/static/img/usp-rad.svg" />
            </div>

            <div class="label">
                <p>Elke week kans op de Lotto&nbsp;Jackpot <br class="hide-for-small"/><span class="bold">van minimaal &euro;&nbsp;2.500.000,-</span></p>
            </div>
        </div>

        <div class="usp">
            <div class="icon-wrapper">
                <img draggable="false" src="/static/img/usp-vip.svg" />
            </div>

            <div class="label">
                <p>Als Lotto abonnee geniet&nbsp;je&nbsp;van <br class="hide-for-small"/><span class="bold">exclusieve abonnee-acties</span></p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {

    };
</script>
