import { v4 as uuidv4 } from "uuid";

export default {
    computed: {
        promotionId() {
            switch (this.$store.getters["prizes/selectedPrize"]) {
                case "lot":
                    return "ead3a14a-fa31-47d6-839c-b08041cc806b";
                    break;
                case "bol":
                    return "000a46d6-3bd4-46e4-9bff-7dfa465f2fd7";
                    break;
                case "mediamarkt":
                    return "1465c063-04a3-4a32-8d8d-e98ea11b3b93";
                    break;
            }
        },

        code() {
            return this.$store.getters["entry/code"];
        },
    },

    methods: {
        handleKoopflowClickout(prize) {
            this.$store.dispatch("prizes/select", prize);

            // if( this.code )
            // {
            //     this.handleCodeFlow();
            //     return;
            // }

            let originalUrl = new URL(window.location.href);

            let sourceToAffiliate = {
                "lot-website": null,
                "lot-search": null,
                "lot-facebook": null,
                "lot-email-leads": null,
                "lot-email-online-players": null,
                "lot-email-winback": null,
                "lot-perfect-data": "PD4",
                "lot-omg": "OMG4",
                // 'lot-affiliate': 'TD',
                "lot-vv": "VV4",
                "lot-vvc": "VVC4",
                "lot-sjs": "SJS4",
                "lot-df": "DF",
            };

            let sourceToLottoMc = {
                "lot-website": "cadeaukaartactiewebsite",
                "lot-websiteab": "cadeaudagenwebsiteab",
                "lot-websitelosseloten": "cadeaudagenlosseloten",
                "lot-search": "cadeaudagensea",
                "lot-affiliate": "cadeaudagentd",
                "lot-display": "cadeaudagendisplay",
                "lot-social": "cadeaudagensocial",
                "lot-email-leads": "cadeaudageneml",
                "lot-email-online-players": "cadeaudagenemo",
                "lot-email-winback": "cadeaudagenemw",
                "lot-perfect-data": "cadeaudagenpd",
                "lot-omg": "cadeaudagenomg",
                "lot-vv": "cadeaudagenvv",
                "lot-vvc": "cadeaudagenvvc",
                "lot-sjs": "cadeaudagensjs",
                "lot-df": "cadeaudagendf",

                // kerst sources
                "lot-am-leads": "cadeaukaartactieeml",
                "lot-am-onl-play": "cadeaukaartactieemo",
                "lot-am-winback": "cadeaukaartactieemw",
                "lot-am-awin": "cadeaukaartactieawin",
                "lot-am-amb": "cadeaukaartactieamb",
                "lot-am-df": "cadeaukaartactiedf",
                "lot-am-sjs": "cadeaukaartactiesjs",
                "lot-am-vvc": "cadeaukaartactievvc",
                "lot-am-tp": "cadeaukaartactietp",

                "lot-am-ckgamma": "cadeaukaartactiegamma",
                "lot-am-ckgammabp": "cadeaukaartactiegammabp",
                "lot-am-ckstop45": "cadeaukaartactiestop45",
                "lot-am-ck1": "cadeaukaartactie1",
                "lot-am-ck2": "cadeaukaartactie2",
                "lot-am-ck3": "cadeaukaartactie3",
                "lot-am-ck4": "cadeaukaartactie4",
                "lot-am-ck5": "cadeaukaartactie5",
                "lot-am-ck6": "cadeaukaartactie6",
                "lot-am-ck7": "cadeaukaartactie7",
                "lot-am-ck8": "cadeaukaartactie8",
                "lot-am-ck9": "cadeaukaartactie9",
                "lot-am-ck10": "cadeaukaartactie10",
            };

            let utmSource = originalUrl.searchParams.get("utm_source");

            let affiliateId = null;
            if (sourceToAffiliate[utmSource]) {
                affiliateId = sourceToAffiliate[utmSource];

                if (this.code) {
                    affiliateId += this.code.match(/([\d])+/)[0];
                } else {
                    affiliateId += uuidv4();
                }
            }

            let obj = {
                lotto_mc: sourceToLottoMc[utmSource]
                    ? sourceToLottoMc[utmSource]
                    : "cadeaukaartactiewebsite",
                promotionId: this.promotionId,
            };

            if (affiliateId !== null) {
                obj["affiliateId"] = affiliateId;
            }

            if (utmSource === "lot-omg") {
                obj["nlo_orgid"] = "OnlineMarketingGroup";
                obj["extcampaign"] = originalUrl.searchParams.get("offer_id");
                obj["extsubid"] = originalUrl.searchParams.get("sub_id");

                delete this.$route.query["offer_id"];
                delete this.$route.query["sub_id"];
            }

            if (utmSource === "lot-perfect-data") {
                obj["nlo_orgid"] = "PerfectData";
                obj["clickid"] = originalUrl.searchParams.get("oa_id");

                delete this.$route.query["oa_id"];
            }

            if (utmSource === "lot-df") {
                obj["nlo_orgid"] = "DataFanatics";
                obj["clickid"] = originalUrl.searchParams.get("clickid");
                delete this.$route.query["clickid"];
            }

            let fullObj = Object.assign(obj, this.$route.query);
            console.log("fullObj", fullObj);
            let baseRedirect =
                "https://lotto.nederlandseloterij.nl/automatisch-meespelen/getallen-kiezen";
            let redirectUrl = baseRedirect;

            let i = 0;
            for (let item in fullObj) {
                if (i === 0) {
                    redirectUrl += "?";
                } else {
                    redirectUrl += "&";
                }

                redirectUrl += item + "=" + fullObj[item];

                i++;
            }
            console.log("redirect url:", redirectUrl);
            window.location.href = redirectUrl;
        },

        handleCodeFlow() {
            let codeToLottoMc = {
                GG: "cadeaudagenalgemeen",
                BD: "cadeaudagenbestdeal",
                ED: "cadeaudagenelkedag",
                HH: "cadeaudagenhemdvoorhem",
                IW: "cadeaudageninktweb",
                TB: "cadeaudagentombv",
                VD: "cadeaudagenvisiondirect",
                WC: "cadeaudagenwebcompanies",
                KD: "cadeaudagenkabeldirect",
                KN: "cadeaudagenknaldeals",
                MM: "cadeaudagenmediamarkt",
                MU: "cadeaudagenmucha",
                MI: "cadeaudagenmister",
                MS: "cadeaudagenmobile",
                IM: "cadeaudageninformatique",
                FK: "cadeaudagenfietskrat",
                CN: "cadeaudagenconrad",
                DD: "cadeaudagendeal",
                AN: "cadeaudagenanwb",
                CT: "cadeaudagencolumbus",
                FT: "cadeaudagenfiets",
                AC: "cadeaudagenacsi",
                AU: "cadeaudagenautoweek",
                BB: "cadeaudagenbeleggers",
                HV: "cadeaudagenvisblad",
                AA: "cadeaudagenarts",
                TT: "cadeaudagentruck",
                OO: "cadeaudagenoor",
                KK: "cadeaudagenkijk",
                WQ: "cadeaudagenwinq",
                FO: "cadeaudagenformule",
                TG: "cadeaudagentop",
                JF: "cadeaudagenjfk",
                CC: "cadeaudagenclassic",
                GW: "cadeaudagenwatch",
                PO: "cadeaudagenpromotor",
                HF: "cadeaudagenhellofresh",
            };

            let originalUrl = new URL(window.location.href);
            let rSource = originalUrl.searchParams.get("r");
            let aOrBTest = rSource === "cadeaudagen" ? "a" : "b";

            let leadingLetters = this.code.substring(0, 2);

            let urlObj = {
                promotionId: this.promotionId,
            };

            if (codeToLottoMc[leadingLetters]) {
                urlObj["lotto_mc"] = codeToLottoMc[leadingLetters];
            } else {
                urlObj["lotto_mc"] = codeToLottoMc[leadingLetters];
            }

            if (leadingLetters !== "HF") {
                urlObj["lotto_mc"] += aOrBTest;
            }

            let baseRedirect =
                "https://lotto.nederlandseloterij.nl/automatisch-meespelen/getallen-kiezen";
            let redirectUrl = baseRedirect;

            let i = 0;
            for (let item in urlObj) {
                if (i === 0) {
                    redirectUrl += "?";
                } else {
                    redirectUrl += "&";
                }

                redirectUrl += item + "=" + urlObj[item];

                i++;
            }

            window.location.href = redirectUrl;
        },
    },
};
