import Vue from 'vue';
import Vuex from 'vuex';

import entry from '@/app/store/modules/entry';
import prizes from '@/app/store/modules/prizes';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        entry,
        prizes
    },

    state: {

    },

    getters: {

    },

    mutations: {

    },

    actions: {

    }
});
