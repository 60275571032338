<template>
    <button :class="{ 'lotto': true }">
        <slot></slot>
        <svg viewBox="0 0 19 12" :class="{ 'arrow': true }"><use xlink:href="#icon-arrow-right"></use></svg>
    </button>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {
        props: {
            arrow: {
                type: Boolean,
                default: false
            }
        }
    };
</script>
