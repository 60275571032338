<template>
    <div :class="{ 'prize-block': true, 'selected': ( selected || hovering ), populair: populair }" :data-prize="name">

        <div class="bg"></div>
        <div class="populair-label-wrapper" v-if="populair">
            <span>Populair</span>
        </div>

        <div class="prize-visual">
            <img draggable="false" :src="visual" />
        </div>

        <h2>{{ displayName }}</h2>
        <p>ter waarde van &euro;&nbsp;{{ twv }},-</p>

        <v-lotto-button :class="{ 'lotto': true, 'black': ( selected || hovering ) }" @click.native="handleKoopflowClickout( name )">Claim je cadeau</v-lotto-button>

    </div>
</template>

<style lang="scss" scoped>
    .prize-block{

    }
</style>

<script>
    import LottoButton from '@/app/components/LottoButton';
    import { koopflowClickout } from '@/app/mixins';

    export default {
        props: {
            name: {
                type: String,
                default: ''
            },

            displayName: {
                type: String,
                default: ''
            },

            visual: {
                type: String,
                default: ''
            },

            twv: {
                type: String,
                default: ''
            },

            populair: {
                type: Boolean,
                default: false
            },

            selected: {
                type: Boolean,
                default: false
            }
        },

        components: {
            'v-lotto-button': LottoButton
        },

        mixins: [ koopflowClickout ],

        computed: {
            hovering()
            {
                return this.$store.getters['prizes/currentHover']?.name === this.name;
            }
        },

        mounted()
        {
        }
    };
</script>
