<template>
    <div class="lotto-logo-wrapper">
        <div class="lotto-logo hide-for-small">
            <a href="https://lotto.nederlandseloterij.nl/" target="_blank">
                <img draggable="false" src="/static/img/logos/lotto.png" />
                <p>
                    <svg class="arrow">
                        <use xlink:href="#icon-arrow-right" href="#icon-arrow-right"></use>
                    </svg>
                    terug naar lotto.nl
                </p>
            </a>
        </div>

        <div class="lotto-bar hide-for-medium-up">
            <a href="https://lotto.nederlandseloterij.nl" target="_blank">
                <img draggable="false" src="/static/img/logo_transparent@2x.png"/>
            </a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    export default {
        name: 'LottoLogo'
    };
</script>
