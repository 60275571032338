<template>
    <div class="landing-view">
        <div class="always-100vh-section">
            <div class="campaign-visual-wrapper">
                <img class="parasol" src="/static/img/parasol.svg" />
                <img class="palm1" src="/static/img/palm1.svg" />
                <img class="palm2" src="/static/img/palm2.svg" />
                <img
                    class="cv"
                    draggable="false"
                    src="/static/img/campagnevisual-zomer.png?v2"
                />
                <img
                    class="cv-glow"
                    draggable="false"
                    src="/static/img/cv-glow.png"
                />
            </div>

            <h1>Welk cadeau <span class="bold">kies jij?</span></h1>

            <p>
                Speel <span class="bold">automatisch</span> mee met Lotto en
                <br />kies &eacute;&eacute;n van de
                <span class="bold">3 welkomstcadeaus</span>!
            </p>

            <v-prizepicker></v-prizepicker>

            <scroll-indicator ref="scroll"></scroll-indicator>
        </div>

        <div class="usp-content">
            <h2>Abonnee voordelen</h2>

            <v-usp-list></v-usp-list>
        </div>

        <!-- <transition name="fade">
            <v-code-panel
                v-if="actiecodeRequired && !hasActiecode"
            ></v-code-panel>
        </transition> -->
    </div>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>

<script>
import { gsap } from "@/vendor";

import PrizePicker from "@/app/components/PrizePicker";
import ScrollIndicator from "@/app/components/ScrollIndicator";
import UspList from "@/app/components/UspList";
// import CodePanel from "@/app/components/CodePanel";

export default {
    components: {
        "v-prizepicker": PrizePicker,
        "scroll-indicator": ScrollIndicator,
        "v-usp-list": UspList,
        // "v-code-panel": CodePanel,
    },

    data() {
        return {
            actiecodeRequired: false,
        };
    },

    computed: {
        hasActiecode() {
            return this.$store.getters["entry/code"];
        },
    },

    mounted() {
        let originalUrl = new URL(window.location.href);
        let printInsert = originalUrl.searchParams.get("r");

        if (printInsert === "cadeau" || printInsert === "cadeaudagen") {
            this.actiecodeRequired = true;
        }
    },

    methods: {
        getAnimateIn() {
            let tl = gsap.timeline({ delay: 1 });

            // parasol
            // palm1
            //palm2

            tl.from(this.$el.querySelector(".always-100vh-section").children, {
                opacity: 0,
                stagger: 0.05,
            }, 0 );

            tl.from( '.palm1', { scale: 0.3, y: 80, ease: 'back.out', duration: 0.7 }, 1 );
            tl.from( '.palm2', { scale: 0.3, y: 80, x: 50, ease: 'back.out', duration: 0.7 }, 1.2 );

            tl.set( '.parasol', { transformOrigin: '0 100%', rotation: 35 }, 0 );

            tl.from( '.parasol', { scale: 0.5, y: 75, x: -50 }, 1.7 );
            tl.to( '.parasol', { rotation: 0, ease: 'sine.inOut' }, 2 )

            return tl;
        },
    },
};
</script>
