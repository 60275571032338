export default {
    namespaced: true,

    state: {
        code: null,
        selectedPrize: 'lot'
    },

    getters: {
        code: state => state.code,
        selectedPrize: state => state.selectedPrize
    },

    mutations: {
        setCode: ( state, payload ) =>
        {
            state.code = payload;
        },

        setSelectedPrize: ( state, payload ) =>
        {
            state.selectedPrize = payload;
        }
    },

    actions: {
        changeSelectedPrize: ( context, payload ) =>
        {
            context.commit( 'setSelectedPrize', payload );
        },

        setCode: ( context, payload ) =>
        {
            context.commit( 'setCode', payload );
        },

        checkCode: ( context, payload ) =>
        {
            let codes = [
                'GG', 'BD', 'ED', 'HH', 'IW', 'TB', 'VD', 'WC', 'KD', 'KN', 'MM', 'MU', 'MI', 'MS', 'IM', 'FK', 'CN', 'DD', 'AN', 'CT', 'FT', 'AC', 'AU', 'BB', 'HV', 'AA', 'TT', 'OO', 'KK', 'WQ', 'FO', 'TG', 'JF', 'CC', 'GW', 'PO', 'HF'
            ];

            let found = codes.find( item =>
            {
                return payload.indexOf( item ) === 0;
            });

            if( found )
            {
                context.commit( 'setCode', payload );
                return Promise.resolve({ correct: true });
            }
            else
            {
                return Promise.resolve({ correct: false });
            }
        },
    }
};
