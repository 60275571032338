/*
    purpose:
    get browser vars

    set platform (windows/macos/ios/android)
    set platform version / os version

    set browser
    set browser version

    set iphone specific vars
*/

let browserVars = {
    userAgent: window.navigator.userAgent,
    platform: window.navigator.platform
};

let check = {
    platform: null,
    platformVersion: null,
    browser: null,
    browserVersion: null,
    iPhoneModel: null,
    iPhoneModelNr: null
};

function init()
{
    check.platform = getPlatform();
    check.platformVersion = getPlatformVersion();
    check.browser = getBrowser();
    check.browserVersion = getBrowserVersion();

    check.iPhoneModel = getIphoneModel();
    check.iPhoneModelNr = getIphoneModelNr();

    window.browsercheck = check;

    return check;
}

export default {
    data()
    {
        return {
            browsercheck: init()
        };
    }
};

// ------------------------

function getPlatform()
{
    let platform = null;

    let allowedPlatforms = {
        windows: [ 'Win32', 'Win64', 'Windows', 'WinCE' ],
        macos: [ 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K' ],
        ios: [ 'iPhone', 'iPad', 'iPod', 'iPhone Simulator', 'iPad Simulator', 'iPod Simulator' ]
    };

    for( let key in allowedPlatforms )
    {
        // for macos, ios & windows
        if( allowedPlatforms[key].indexOf( browserVars.platform ) > -1 )
        {
            platform = key;
        }
    }

    if( new RegExp( 'Android', 'gi' ).test( browserVars.userAgent ) )
    {
        platform = 'android';
    }

    if( !platform && new RegExp( 'Linux', 'gi' ).test( platform ) )
    {
        platform = 'linux';
    }

    return platform;
}

// ----------------------------

function getPlatformVersion()
{
    // only ios for now
    let platformVersion = null;

    if( check.platform === 'ios' )
    {
        platformVersion = browserVars.userAgent.match( /iPhone OS (.*) like Mac OS/ )[1];
    }

    if( check.platform === 'android' )
    {
        platformVersion = browserVars.userAgent.match( /Android (.*);/ )[1];
    }

    return platformVersion;
}

// ---------------------------

function getBrowser()
{
    let browser = null;

    let detectors = {
        chrome: () =>
        {
            if( check.platform === 'ios' )
            {
                return browserVars.userAgent.indexOf( 'CriOS' ) !== -1;
            }
            else
            {
                return browserVars.userAgent.indexOf( 'Safari' ) !== -1 && browserVars.userAgent.indexOf( 'Chrome' ) > -1;
            }
        },
        safari: () => browserVars.userAgent.indexOf( 'Safari' ) !== -1 && browserVars.userAgent.indexOf( 'Chrome' ) === -1 && browserVars.userAgent.indexOf( 'CriOS' ) === -1,
        firefox: () => browserVars.userAgent.indexOf( 'Firefox' ) !== -1,
        edge: () => browserVars.userAgent.indexOf( 'Edg/' ) !== -1,
        samsung: () => browserVars.userAgent.indexOf( 'SamsungBrowser' ) !== -1,
        opera: () => browserVars.userAgent.indexOf( 'OPR/' ) !== -1
    };

    for( let key in detectors )
    {
        let result = detectors[key]();
        if( result === true )
        {
            browser = key;
        }
    }

    return browser;
}

// -------------------------

function getBrowserVersion()
{
    let browserVersion = null;

    let regex = new RegExp( check.browser + '/\\d+', 'gi' );
    let foundVersion = browserVars.userAgent.match( regex );

    if( foundVersion && foundVersion.length > 0 )
    {
        browserVersion = foundVersion[0].split( '/' )[1];
    }

    return browserVersion;
}

// ------------------------

function getIphoneModel()
{
    // https://51degrees.com/Blog/Website-Optimisation-For-Apple-Devices-Ipad-And-Iphone
    // Get renderer info from canvas.

    if( check.platform !== 'ios' )
    {
        return null;
    }

    let canvas = document.createElement( 'canvas' );
    let renderer, info, context;

    if( canvas )
    {
        context = canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' );
        if( context )
        {
            info = context.getExtension( 'WEBGL_debug_renderer_info' );
            if( info )
            {
                renderer = context.getParameter( info.UNMASKED_RENDERER_WEBGL );
            }
        }
    }

    let ratio = window.devicePixelRatio;

    if( window.screen.height / window.screen.width === 844 / 390 )
    {
        switch( ratio )
        {
            default: return 'empty1';
            case 2: return 'empty2';
            case 3: return 'iPhone 12, iPhone 12 Pro';
        }
    }

    // iPhone XR, iPhone XS Max
    if( window.screen.height / window.screen.width === 896 / 414 )
    {
        if( renderer === 'Apple A12 GPU' )
        {
            switch( ratio )
            {
                default: return 'iPhone XR, iPhone XS Max';
                case 2: return 'iPhone XR';
                case 3: return 'iPhone XS Max';
            }
        }
        else if( renderer === 'Apple GPU' )
        {
            switch( ratio )
            {
                default: return 'empty0';
                case 2: return 'iPhone 11';
                case 3: return 'iPhone 11 Pro Max, iPhone 12 Pro Max';
            }
        }
    }

    // iPhone X, iPhone XS

    else if( window.screen.height / window.screen.width === 812 / 375 )
    {
        switch( renderer )
        {
            default: return 'iPhone X, iPhone XS';
            case 'Apple A11 GPU': return 'iPhone X';
            case 'Apple A12 GPU': return 'iPhone XS';
            // case 'Apple GPU': return 'iPhone 11 Pro, iPhone 12 Mini'
        }
    }

    // iPhone 6+, iPhone 6s+, iPhone 7+, iPhone 8+
    else if( window.screen.height / window.screen.width === 736 / 414 )
    {
        switch( renderer )
        {
            default: return 'iPhone 6 Plus, 6s Plus, 7 Plus or 8 Plus';
            case 'Apple A8 GPU': return 'iPhone 6 Plus';
            case 'Apple A9 GPU': return 'iPhone 6s Plus';
            case 'Apple A10 GPU': return 'iPhone 7 Plus';
            case 'Apple A11 GPU': return 'iPhone 8 Plus';
        }
    }

    // iPhone 6, iPhone 6s, iPhone 7, iPhone 8
    else if( window.screen.height / window.screen.width === 667 / 375 )
    {
        if( ratio === 2 )
        {
            switch( renderer )
            {
                default: return 'iPhone 6, 6s, 7 or 8';
                case 'Apple A8 GPU': return 'iPhone 6';
                case 'Apple A9 GPU': return 'iPhone 6s';
                case 'Apple A10 GPU': return 'iPhone 7';
                case 'Apple A11 GPU': return 'iPhone 8';
            }
        }
        else
        {
            // or in zoom mode: iPhone 6+, iPhone 6S+, iPhone 7+, iPhone 8+
            switch( renderer )
            {
                default: return 'iPhone 6 Plus, 6s Plus , 7 Plus or 8 Plus (display zoom)';
                case 'Apple A8 GPU': return 'iPhone 6 Plus (display zoom)';
                case 'Apple A9 GPU': return 'iPhone 6s Plus (display zoom)';
                case 'Apple A10 GPU': return 'iPhone 7 Plus (display zoom)';
                case 'Apple A11 GPU': return 'iPhone 8 Plus (display zoom)';
            }
        }
    }

    // iPhone 5, iPhone 5C, iPhone 5S, iPhone SE
    // or in zoom mode: iPhone 5, iPhone 5C, iPhone 5S, iPhone SE, iPhone 6, iPhone 6S, iPhone 7 or iPhone 8

    else if( window.screen.height / window.screen.width === 1.775 )
    {
        switch( renderer )
        {
            default: return 'iPhone 5, 5C, 5S, SE or 6, 6s, 7 and 8 (display zoom)';
            case 'PowerVR SGX 543': return 'iPhone 5 or 5c';
            case 'Apple A7 GPU': return 'iPhone 5s';
            case 'Apple A8 GPU': return 'iPhone 6 (display zoom)';
            case 'Apple A9 GPU': return 'iPhone SE or 6s (display zoom)';
            case 'Apple A10 GPU': return 'iPhone 7 (display zoom)';
            case 'Apple A11 GPU': return 'iPhone 8 (display zoom)';
        }
    }

    // iPhone 4 or 4s
    else if( ( window.screen.height / window.screen.width === 1.5 ) && ( ratio === 2 ) )
    {
        switch( renderer )
        {
            default: return 'iPhone 4 or 4s';
            case 'PowerVR SGX 535': return 'iPhone 4';
            case 'PowerVR SGX 543': return 'iPhone 4s';
        }
    }

    // iPhone 1, 3G or 3GS

    else if( ( window.screen.height / window.screen.width === 1.5 ) && ( ratio === 1 ) )
    {
        switch( renderer )
        {
            default: return 'iPhone 1, 3G or 3GS';
            case 'ALP0298C05': return 'iPhone 3GS';
            case 'S5L8900': return 'iPhone 1, 3G';
        } // Not an iPhone.
    }
    else
    {
        return 'Not an iPhone';
    }
}

function getIphoneModelNr()
{
    if( check.iPhoneModel )
    {
        if( check.iPhoneModel.indexOf( 'X' ) > -1 )
        {
            return [10];
        }
        else
        {
            let versionArray = check.iPhoneModel.match( /\d+/g );
            for( let i = 0; i < versionArray.length; i++ )
            {
                versionArray[i] = parseInt( versionArray[i], 10 );
            }

            return versionArray;
        }
    }
}
