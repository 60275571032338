<template>
    <div class="prize-picker">
        <div class="flexer">

            <v-prizeblock v-for="(prize, index) in prizes" :key="index" v-bind="prize" :selected="selectedPrize === prize.name && !currentHover" @click.native="extraClickout( prize )" @mouseenter.native="onMouseEnter( prize.name )" @mouseleave.native="onMouseLeave( prize.name  )"></v-prizeblock>

        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import PrizeBlock from '@/app/components/PrizeBlock';
    import { koopflowClickout, browsercheck } from '@/app/mixins';

    export default {
        components: {
            'v-prizeblock': PrizeBlock
        },

        mixins: [ koopflowClickout, browsercheck ],

        computed: {
            prizes()
            {
                return this.$store.getters['prizes/data'];
            },

            selectedPrize()
            {
                return this.$store.getters['prizes/selectedPrize'];
            },

            currentHover()
            {
                return this.$store.getters['prizes/currentHover']?.name;
            }
        },

        methods: {
            setSelected( pBlock )
            {
                this.$store.dispatch( 'prizes/select', pBlock );
            },

            onMouseEnter( pBlock )
            {
                this.$store.dispatch( 'prizes/setHover', pBlock );
            },

            onMouseLeave( pBlock )
            {
                this.$store.dispatch( 'prizes/unsetHover', pBlock );
            },

            extraClickout( pPrize )
            {
                if( window.innerWidth > 658 )
                {
                    this.handleKoopflowClickout( pPrize.name );
                }
                else
                {
                    this.$store.dispatch( 'prizes/select', pPrize.name );
                }
            }
        }
    };
</script>
