<template>
    <div :class="{ 'quick-bar': true, hide: !showBar }">
        <div class="chosen-prize">
            <p>Jouw cadeau keuze:</p>
            <h2>{{ selectedPrizeName }}</h2>
        </div>

        <v-lotto-button
            class="black"
            :arrow="true"
            @click.native="handleKoopflowClickout(selectedPrize)"
            >Dit wordt &rsquo;m</v-lotto-button
        >
    </div>
</template>

<style lang="scss" scoped></style>

<script>
import LottoButton from "@/app/components/LottoButton";
import { koopflowClickout } from "@/app/mixins";
import Vue from "vue";

export default {
    components: {
        "v-lotto-button": LottoButton,
    },

    mixins: [koopflowClickout],

    data() {
        return {
            showBar: true,
        };
    },

    computed: {
        selectedPrize() {
            return this.$store.getters["prizes/selectedPrize"];
        },

        selectedPrizeName() {
            return this.$store.getters["prizes/selectedPrizeData"].displayName;
        },
    },

    mounted() {
        this.prizePickerOffset = null;
        setTimeout(() => {
            this.prizePickerOffset =
                document.querySelector(".prize-picker").offsetTop;
        }, 500);

        // this.scrollRef = this.onScroll.bind(this);
        // window.addEventListener("scroll", this.scrollRef);
    },

    beforeDestroy() {
        // window.removeEventListener("scroll", this.scrollRef);
    },

    methods: {
        onScroll(e) {
            let scrollPos =
                window.scrollY ||
                window.scrollTop ||
                document.getElementsByTagName("html")[0].scrollTop;

            if (scrollPos > this.prizePickerOffset) {
                this.showBar = true;
            } else {
                this.showBar = false;
            }
        },
    },
};
</script>
