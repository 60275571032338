<template>
    <div class="main-layout">
        <!-- <v-lotto-logo></v-lotto-logo> -->

        <slot></slot>

        <footer>
            <div class="content-wrapper">
                <img
                    draggable="false"
                    src="/static/img/logos/78838313048c6735eed3c16994dd962f.png"
                />
                <img
                    draggable="false"
                    class="big"
                    src="/static/img/logos/316d44c573c9eaacf635bcd6e28e23d0.png"
                />
                <p class="disclaimer small">
                    Voor het organiseren van Lotto is aan Lotto B.V. vergunning
                    verleend door de Kansspelautoriteit, kenmerk 10510 d.d. 23
                    december 2016. Trekking Laan van Hoornwijck 55 te
                    Rijswijk.<br class="hide-for-small" />Deelname vanaf 18
                    jaar. Als je aan deze actie meedoet, dan accepteer je onze
                    <a
                        @click="trackAv"
                        href="https://lotto-cdn.nederlandseloterij.nl/assets/6ebe732a-4adc-44f3-9349-c35bd9ec291a?x=0&y=0&c=40f075869c903657e77d0604edf6c9dd72fa17007e4ee55d3d89b51627ec4c66"
                        target="_blank"
                        >actievoorwaarden</a
                    >. |
                    <a
                        @click="trackPrivacyClickout"
                        href="https://www.nederlandseloterij.nl/privacyloterijen"
                        target="_blank"
                        >Privacy Statement</a
                    >
                    Nederlandse Loterij
                </p>
            </div>
        </footer>

        <v-quickbar class="hide-for-medium-up"></v-quickbar>
    </div>
</template>

<style lang="scss" scoped>
.main-layout {
    position: relative;
    height: 100%;
}
</style>

<script>
import { tracking } from "@/app/mixins";
import LottoLogo from "@/app/components/LottoLogo";
import QuickBar from "@/app/components/QuickBar";

export default {
    components: {
        "v-lotto-logo": LottoLogo,
        "v-quickbar": QuickBar,
    },

    mixins: [tracking],

    methods: {
        trackAv() {
            this.trackEvent(
                "Klik op link - Actievoorwaarden",
                "Link tracking",
                "https://lotto-cdn.nederlandseloterij.nl/assets/6ebe732a-4adc-44f3-9349-c35bd9ec291a?x=0&y=0&c=40f075869c903657e77d0604edf6c9dd72fa17007e4ee55d3d89b51627ec4c66"
            );
        },

        trackPrivacyClickout() {
            this.trackEvent(
                "Klik op link - Privacy Statement",
                "Link tracking",
                "Nieuw venster - Privacy Statement - https://www.nederlandseloterij.nl/privacyloterijen"
            );
        },
    },
};
</script>
