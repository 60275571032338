<template>
    <div id="app">
        <div class="background-wrapper" ref="backgroundWrapper">
            <img class="wolken" src="/static/img/wolken.svg" />
            <img class="wolk" src="/static/img/wolk.svg" />
        </div>
        <svg style="display:none">
            <symbol viewBox="0 0 11 6" id="icon-chevron"><path d="M10.146.146a.5.5 0 0 1 .708.708l-5 5a.5.5 0 0 1-.708 0l-5-5A.5.5 0 0 1 .854.146L5.5 4.793 10.146.146z"></path></symbol>
            <symbol viewBox="0 0 19 12" id="icon-arrow-right"><path d="M12.646 1.354a.5.5 0 0 1 .708-.708l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L17.293 6l-4.647-4.646z"></path><path d="M1 6.5a.5.5 0 0 1 0-1h16a.5.5 0 1 1 0 1H1z"></path></symbol>

            <symbol viewBox="0 0 74 75" id="usp-vip">
                <g transform="translate(0.048 -0.071)">
                    <path style="opacity:0.5; fill:#FFFFFF;" d="M37,1.6L37,1.6c19.9,0,36,15.9,36,35.5v1c0,19.6-16.1,35.5-36,35.5l0,0c-19.9,0-36-15.9-36-35.5v-1
                        C1,17.5,17.1,1.6,37,1.6z"/>
                    <g transform="translate(9.7 14.542)">
                        <path d="M53.7,20.9l-3.6-6.2l-0.2,0.1c0,0-0.1,0.1-0.1,0.1c-2,1.2-4.6,0.6-5.8-1.3c-1.2-1.9-0.6-4.5,1.3-5.7
                            c0.1,0,0.1-0.1,0.2-0.1l0.1-0.1l0.1-0.1l0.2-0.1l-0.2-0.4V7.2l0,0l-3.2-5.4c-0.2-0.4-0.7-0.7-1.2-0.7c-0.2,0-0.5,0.1-0.7,0.2
                            L2.2,23.1c-0.6,0.4-0.9,1.2-0.5,1.8l11.2,19.2l0,0c0.4,0.6,1.2,0.8,1.8,0.5l38.4-21.9C53.8,22.3,54,21.5,53.7,20.9z M34.1,6.8
                            l7.1-4.1l2.6,4.4c-2.4,2.1-2.6,5.7-0.5,8.1c1.5,1.7,4,2.3,6.1,1.6l2.7,4.7L45,25.6L34.1,6.8z M43.8,26.2L14.2,43.1l-11-18.8
                            l29.8-17L43.8,26.2z"/>
                        <path d="M13.8,26.4l-1.7,1l7.1,5.7l1.6-0.9l-1.5-8.9l-1.7,0.9l1.2,6.4l0,0L13.8,26.4z"/>
                        <polygon points="20.6,22.5 22.2,21.6 26.4,28.9 24.9,29.8        "/>
                        <path d="M30,26.9l-1.6-2.8l1.6-0.9c1.7-1,1.9-2.4,1.2-3.7c-0.7-1.3-2.1-1.9-3.9-0.8l-3.2,1.8l4.3,7.3L30,26.9z M26.6,20.9l1.6-0.9
                            c0.5-0.3,1.1-0.2,1.5,0.2c0.3,0.5,0.2,1.1-0.2,1.4c-0.1,0-0.1,0.1-0.2,0.1l-1.6,0.9L26.6,20.9z"/>
                    </g>
                </g>
            </symbol>
        </svg>

        <ynk-layout>
            <transition mode="out-in"
                        @enter="getAnimateIn"
                        @leave="getAnimateOut"
                        :css="false"
                        appear>
                <router-view :key="$route.path" ref="currentView"></router-view>
            </transition>
        </ynk-layout>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';
</style>

<script>
    import { gsap } from '@/vendor';

    import Main from '@/app/layouts/Main';

    export default {
        name: 'App',

        components: {
            'ynk-layout': Main
        },

        mounted()
        {
            window.addEventListener( 'resize', this.onResize );
            this.onResize();
            setTimeout( () => {
                this.onResize();
            }, 50 )
        },

        methods: {
            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones

                // --sky-end
                // --sea-end
                // --beach-end
                if( window.innerWidth < 728 )
                {
                    let prizePickerTop = document.querySelector('.prize-picker').getBoundingClientRect().top + window.scrollY;
                    this.$refs.backgroundWrapper.style.setProperty( '--sky-end', (prizePickerTop - 20) + 'px' );
                    this.$refs.backgroundWrapper.style.setProperty( '--sea-end', (prizePickerTop + 125) + 'px' );
                }
            },

            getAnimateIn( el, done )
            {
                let tl = gsap.timeline({ onComplete: done });
                tl.add( () => { this.onResize() }, 0.3 );
                tl.from( '#app', { opacity: 0.01, duration: 0.3 }, 0.3 );
                if( typeof this.$refs.currentView.getAnimateIn === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateIn(), 0 );
                }
                else
                {
                    done();
                }
            },

            getAnimateOut( el, done )
            {
                let tl = gsap.timeline({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateOut === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateOut(), 0 );
                }
                else
                {
                    done();
                }
            }
        }
    };
</script>
